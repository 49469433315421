import React from 'react';
import {
  StyledGlobalJobBoardFilterInnerWrapper,
  StyledGlobalJobBoardFilterItemWrapper,
  StyledGlobalJobBoardFilterMainWrapper,
} from './global-job-board-filter.styled';
import { Text, font } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import { DownArrow } from '../../../../../icons/arrow-down';

interface FilterItemsDataTypes {
  name: string;
}

const filterItemsData: FilterItemsDataTypes[] = [
  {
    name: 'Industry',
  },
  {
    name: 'Type of Employment',
  },
  {
    name: 'Worksite',
  },
  {
    name: 'Experience',
  },
  {
    name: 'Salary Range',
  },
];

const GlobalJobBoardFilter: React.FC = () => {
  return (
    <StyledGlobalJobBoardFilterMainWrapper>
      <StyledGlobalJobBoardFilterInnerWrapper>
        {filterItemsData.map((filterItem, index) => (
          <StyledGlobalJobBoardFilterItemWrapper
            key={index}
            isLast={index === filterItemsData.length - 1}
          >
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.neutral_800}
              margin="0"
            >
              {filterItem.name}
            </Text>
            <DownArrow />
          </StyledGlobalJobBoardFilterItemWrapper>
        ))}
      </StyledGlobalJobBoardFilterInnerWrapper>
    </StyledGlobalJobBoardFilterMainWrapper>
  );
};

export default GlobalJobBoardFilter;
