import React from 'react';
import {
  StyledGlobalJobBoardHeroSearchBtn,
  StyledGlobalJobBoardHeroSearchInput,
  StyledGlobalJobBoardHeroSearchInputAndIconWrapper,
  StyledGlobalJobBoardHeroSearchInputIconWrapper,
  StyledGlobalJobBoardHeroSearchInputSeparator,
  StyledGlobalJobBoardHeroSearchWrapper,
  StyledGlobalJobBoardHeroTitleAndSearchMainWrapper,
  StyledGlobalJobBoardHeroTitleWrapper,
} from './global-job-board-hero-left-component.styled';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import { LightGreySearchIcon } from '../../../../../../../icons/light-grey-search-icon';
import { LightGreyLocationIcon } from '../../../../../../../icons/light-grey-location-icon';

const GlobalJobBoardHeroLeftComponent: React.FC = () => {
  return (
    <StyledGlobalJobBoardHeroTitleAndSearchMainWrapper>
      <StyledGlobalJobBoardHeroTitleWrapper>
        <div>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={72}
            fontWeight={600}
            lineHeight="79.2px"
            color={colors.base_black}
            margin="0"
          >
            Empower your
          </Text>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={72}
            fontWeight={600}
            lineHeight="79.2px"
            color={colors.base_black}
            margin="0"
          >
            Career&nbsp;
            <span style={{ color: colors.secondary_500 }}>Journey</span>
          </Text>
        </div>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={18}
          fontWeight={400}
          lineHeight="26px"
          color={colors.base_black}
          margin="0"
        >
          Search and find your dream job now easier then ever,
          <br />
          you can simply brows and find job!
        </Text>
      </StyledGlobalJobBoardHeroTitleWrapper>
      <StyledGlobalJobBoardHeroSearchWrapper>
        <StyledGlobalJobBoardHeroSearchInputAndIconWrapper>
          <StyledGlobalJobBoardHeroSearchInput
            type="text"
            placeholder="Job title"
          />
          <StyledGlobalJobBoardHeroSearchInputIconWrapper>
            <LightGreySearchIcon />
          </StyledGlobalJobBoardHeroSearchInputIconWrapper>
        </StyledGlobalJobBoardHeroSearchInputAndIconWrapper>
        <StyledGlobalJobBoardHeroSearchInputSeparator />
        <StyledGlobalJobBoardHeroSearchInputAndIconWrapper>
          <StyledGlobalJobBoardHeroSearchInput
            type="text"
            placeholder="Location"
          />
          <StyledGlobalJobBoardHeroSearchInputIconWrapper>
            <LightGreyLocationIcon />
          </StyledGlobalJobBoardHeroSearchInputIconWrapper>
        </StyledGlobalJobBoardHeroSearchInputAndIconWrapper>
        <StyledGlobalJobBoardHeroSearchBtn>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={500}
            lineHeight="19.5px"
            color={colors.white}
            margin="0"
          >
            Search
          </Text>
        </StyledGlobalJobBoardHeroSearchBtn>
      </StyledGlobalJobBoardHeroSearchWrapper>
    </StyledGlobalJobBoardHeroTitleAndSearchMainWrapper>
  );
};

export default GlobalJobBoardHeroLeftComponent;
