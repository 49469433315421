import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';

export const StyledGlobalJobBoardActiveJobsMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const StyledGlobalJobBoardActiveJobsFilterAndTotalResults = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledGlobalJobBoardActiveJobsSortyByMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 8px;
`;

export const StyledGlobalJobBoardActiveJobsSortyBy = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 8px;
  cursor: pointer;
`;

export const StyledGlobalJobBoardActiveJobsShowMoreBtn = styled.button`
  box-sizing: border-box;
  width: fit-content;
  padding: 12px 16px;
  border-radius: 24px;
  border: 1px solid ${colors.secondary_400};
  background-color: transparent;
  margin: 24px auto;
  cursor: pointer;
`;
