import React from 'react';
import {
  StyledGlobalJobBoardJobDetailsCompanyInfoWrapper,
  StyledGlobalJobBoardJobDetailsCompanyInfoWrapperSeparator,
  StyledGlobalJobBoardJobDetailsCompanyLogo,
  StyledGlobalJobBoardJobDetailsCompanyTitleAndInfo,
  StyledGlobalJobBoardJobDetailsCoverImg,
  StyledGlobalJobBoardJobDetailsInfoBackBtn,
  StyledGlobalJobBoardJobDetailsInfoMainWrapper,
  StyledGlobalJobBoardJobDetailsInfoWrapper,
  StyledGlobalJobBoardJobDetailsJobPositionAndDate,
  StyledGlobalJobBoardJobDetailsShareAndApplyBtn,
  StyledGlobalJobBoardJobDetailsShareAndApplyBtnsWrapper,
  StyledGlobalJobBoardJobDetailsStatusCardMainWrapper,
  StyledGlobalJobBoardJobDetailsStatusCardWrapper,
  StyledGlobalJobBoardJobDetailsTitleAndTextWrapper,
} from './global-job-board-job-details-info.styled';
import { Text, font } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';
import { LeftArrowSettings } from '../../../../../icons/arrow-left-settings';
import MissingLogoPlaceholder from '../../../../../../assets/images/no_logo.jpg';
import GlobalJobBoardCetailsCover from '../../../../../../assets/images/global-job-board-details-cover.png';
import { NavigateFunction } from 'react-router-dom';
import { GlobalJobBoardJobAndCompanyDataTypes } from './use-global-job-board-job-and-company-data';

interface GlobalJobBoardJobDetailsInfoProp {
  navigate: NavigateFunction;
  jobData: GlobalJobBoardJobAndCompanyDataTypes | null;
}

const GlobalJobBoardJobDetailsInfo: React.FC<
  GlobalJobBoardJobDetailsInfoProp
> = ({ navigate, jobData }) => {
  return (
    <StyledGlobalJobBoardJobDetailsInfoMainWrapper>
      <StyledGlobalJobBoardJobDetailsInfoBackBtn
        onClick={() => navigate('/global-new')}
      >
        <LeftArrowSettings />
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={14}
          fontWeight={400}
          lineHeight="17.07px"
          color={colors.neutral_400}
          margin="0"
        >
          Back
        </Text>
      </StyledGlobalJobBoardJobDetailsInfoBackBtn>
      <StyledGlobalJobBoardJobDetailsInfoWrapper>
        <StyledGlobalJobBoardJobDetailsCoverImg
          backgroundImage={GlobalJobBoardCetailsCover}
        >
          <StyledGlobalJobBoardJobDetailsCompanyLogo
            src={
              jobData?.company?.profilePicture &&
              jobData.company.profilePicture.trim() !== ''
                ? jobData?.company?.profilePicture
                : MissingLogoPlaceholder
            }
          />
        </StyledGlobalJobBoardJobDetailsCoverImg>
        <StyledGlobalJobBoardJobDetailsCompanyTitleAndInfo>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={18}
            fontWeight={600}
            lineHeight="21.94px"
            color={colors.base_black}
            margin="0"
          >
            {jobData?.company?.name}
          </Text>
          <StyledGlobalJobBoardJobDetailsCompanyInfoWrapper>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.neutral_800}
              margin="0"
            >
              {jobData?.company?.website}
            </Text>
            {jobData?.company?.email &&
              jobData?.company?.email.trim() !== '' && (
                <>
                  <StyledGlobalJobBoardJobDetailsCompanyInfoWrapperSeparator>
                    |
                  </StyledGlobalJobBoardJobDetailsCompanyInfoWrapperSeparator>
                  <Text
                    fontFamily={font.familyMontserrat}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="17.07px"
                    color={colors.neutral_800}
                    margin="0"
                  >
                    {jobData?.company?.email}
                  </Text>
                </>
              )}
            {jobData?.company?.phoneNumber &&
              jobData?.company?.phoneNumber.trim() !== '' && (
                <>
                  <StyledGlobalJobBoardJobDetailsCompanyInfoWrapperSeparator>
                    |
                  </StyledGlobalJobBoardJobDetailsCompanyInfoWrapperSeparator>
                  <Text
                    fontFamily={font.familyMontserrat}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="17.07px"
                    color={colors.neutral_800}
                    margin="0"
                  >
                    {jobData?.company?.phoneNumber}
                  </Text>
                </>
              )}
            {jobData?.company?.location &&
              jobData?.company?.location.trim() !== '' && (
                <>
                  <StyledGlobalJobBoardJobDetailsCompanyInfoWrapperSeparator>
                    |
                  </StyledGlobalJobBoardJobDetailsCompanyInfoWrapperSeparator>
                  <Text
                    fontFamily={font.familyMontserrat}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="17.07px"
                    color={colors.neutral_800}
                    margin="0"
                  >
                    {jobData?.company?.location}
                  </Text>
                </>
              )}
          </StyledGlobalJobBoardJobDetailsCompanyInfoWrapper>
        </StyledGlobalJobBoardJobDetailsCompanyTitleAndInfo>
        <StyledGlobalJobBoardJobDetailsJobPositionAndDate>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={20}
            fontWeight={600}
            lineHeight="24.38px"
            color={colors.base_black}
            margin="0"
          >
            {jobData?.job.jobPosition}
          </Text>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={12}
            fontWeight={400}
            lineHeight="14.63px"
            color={colors.neutral_800}
            margin="0"
          >
            {jobData?.job.expirationDate &&
              new Date(jobData.job.expirationDate).toLocaleDateString('de-DE')}
          </Text>
        </StyledGlobalJobBoardJobDetailsJobPositionAndDate>
        <StyledGlobalJobBoardJobDetailsStatusCardMainWrapper>
          <StyledGlobalJobBoardJobDetailsStatusCardWrapper hasRightBorder>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.base_black}
              margin="0"
            >
              Type of Employment
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={600}
              lineHeight="19.5px"
              color={colors.base_black}
              margin="0"
            >
              {jobData?.job.jobCategory?.trim() || 'No Info'}
            </Text>
          </StyledGlobalJobBoardJobDetailsStatusCardWrapper>
          <StyledGlobalJobBoardJobDetailsStatusCardWrapper hasRightBorder>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.base_black}
              margin="0"
            >
              Worksite
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={600}
              lineHeight="19.5px"
              color={colors.base_black}
              margin="0"
            >
              {jobData?.job.workLocation?.trim() || 'No Info'}
            </Text>
          </StyledGlobalJobBoardJobDetailsStatusCardWrapper>
          <StyledGlobalJobBoardJobDetailsStatusCardWrapper hasRightBorder>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.base_black}
              margin="0"
            >
              Experience
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={600}
              lineHeight="19.5px"
              color={colors.base_black}
              margin="0"
            >
              {jobData?.job.seniorityLevel?.trim() || 'No Info'}
            </Text>
          </StyledGlobalJobBoardJobDetailsStatusCardWrapper>
          <StyledGlobalJobBoardJobDetailsStatusCardWrapper
            hasRightBorder={false}
          >
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={14}
              fontWeight={400}
              lineHeight="17.07px"
              color={colors.base_black}
              margin="0"
            >
              Salary Range
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={600}
              lineHeight="19.5px"
              color={colors.base_black}
              margin="0"
            >
              No info
            </Text>
          </StyledGlobalJobBoardJobDetailsStatusCardWrapper>
        </StyledGlobalJobBoardJobDetailsStatusCardMainWrapper>
        {jobData?.job?.jobDescription?.trim() && (
          <StyledGlobalJobBoardJobDetailsTitleAndTextWrapper>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={18}
              fontWeight={600}
              lineHeight="21.94px"
              color={colors.base_black}
              margin="0"
            >
              Job Description:
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={400}
              lineHeight="19.5px"
              color={colors.neutral_800}
              margin="0"
              style={{ whiteSpace: 'pre-line', display: 'block' }}
              dangerouslySetInnerHTML={{
                __html: jobData.job.jobDescription,
              }}
            />
          </StyledGlobalJobBoardJobDetailsTitleAndTextWrapper>
        )}

        {jobData && jobData.job?.skills?.length > 0 && (
          <StyledGlobalJobBoardJobDetailsTitleAndTextWrapper>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={18}
              fontWeight={600}
              lineHeight="21.94px"
              color={colors.base_black}
              margin="0"
            >
              Skill Required:
            </Text>
            <ul>
              {jobData.job.skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </StyledGlobalJobBoardJobDetailsTitleAndTextWrapper>
        )}
        {jobData?.job?.responsibilities &&
          jobData.job.responsibilities.trim() !== '' && (
            <StyledGlobalJobBoardJobDetailsTitleAndTextWrapper>
              <Text
                fontFamily={font.familyMontserrat}
                fontSize={18}
                fontWeight={600}
                lineHeight="21.94px"
                color={colors.base_black}
                margin="0"
              >
                Key Responsibilities:
              </Text>
              <Text
                fontFamily={font.familyMontserrat}
                fontSize={16}
                fontWeight={400}
                lineHeight="19.5px"
                color={colors.neutral_800}
                margin="0"
                style={{ whiteSpace: 'pre-line', display: 'block' }}
                dangerouslySetInnerHTML={{
                  __html: jobData.job.responsibilities,
                }}
              />
            </StyledGlobalJobBoardJobDetailsTitleAndTextWrapper>
          )}
        {jobData?.job?.benefits && jobData.job.benefits.trim() !== '' && (
          <StyledGlobalJobBoardJobDetailsTitleAndTextWrapper>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={18}
              fontWeight={600}
              lineHeight="21.94px"
              color={colors.base_black}
              margin="0"
            >
              Benefits:
            </Text>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={400}
              lineHeight="19.5px"
              color={colors.neutral_800}
              margin="0"
              style={{ whiteSpace: 'pre-line', display: 'block' }}
              dangerouslySetInnerHTML={{
                __html: jobData.job.benefits,
              }}
            />
          </StyledGlobalJobBoardJobDetailsTitleAndTextWrapper>
        )}
        <StyledGlobalJobBoardJobDetailsShareAndApplyBtnsWrapper>
          <StyledGlobalJobBoardJobDetailsShareAndApplyBtn
            backgroundColor={colors.secondary_500}
          >
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={500}
              lineHeight="19.5px"
              color={colors.white}
              margin="0"
            >
              Share
            </Text>
          </StyledGlobalJobBoardJobDetailsShareAndApplyBtn>
          <StyledGlobalJobBoardJobDetailsShareAndApplyBtn
            backgroundColor={colors.persianGreen}
            onClick={() => navigate(`/global-new/apply/${jobData?.job.jobId}`)}
          >
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={500}
              lineHeight="19.5px"
              color={colors.white}
              margin="0"
            >
              Apply Now
            </Text>
          </StyledGlobalJobBoardJobDetailsShareAndApplyBtn>
        </StyledGlobalJobBoardJobDetailsShareAndApplyBtnsWrapper>
      </StyledGlobalJobBoardJobDetailsInfoWrapper>
    </StyledGlobalJobBoardJobDetailsInfoMainWrapper>
  );
};

export default GlobalJobBoardJobDetailsInfo;
