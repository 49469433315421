import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../../constants/api-constants';
import { JobListing } from '../../components/featured-jobs/global-job-board-featured-jobs-types';

interface JobListingsContextType {
  jobListings: JobListing[];
  jobCount: number;
  isLoading: boolean;
  loadMoreJobs: () => void;
}

const JobListingsContext = createContext<JobListingsContextType | undefined>(
  undefined
);

export const JobListingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [jobListings, setJobListings] = useState<JobListing[]>([]);
  const [jobCount, setJobCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const fetchJobListings = async (newOffset = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${apiRoute}/global-job-board/job-listings`,
        {
          params: { limit, offset: newOffset },
        }
      );
      setJobListings((prevListings) => [
        ...prevListings,
        ...response.data.jobListings,
      ]);
      setJobCount(response.data.totalJobCount);
      setOffset(newOffset);
    } catch (error) {
      console.error('Error fetching job listings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobListings();
  }, []);

  const loadMoreJobs = () => {
    fetchJobListings(offset + limit);
  };

  return (
    <JobListingsContext.Provider
      value={{ jobListings, jobCount, isLoading, loadMoreJobs }}
    >
      {children}
    </JobListingsContext.Provider>
  );
};

export const useJobListings = (): JobListingsContextType => {
  const context = useContext(JobListingsContext);
  if (!context) {
    throw new Error('useJobListings must be used within a JobListingsProvider');
  }

  return context;
};
