import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';
import { font } from '../../../../../../../../style-utils/text';

export const StyledGlobalJobBoardJobListingMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  background-color: ${colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${colors.ghostWhite};
  }
`;

export const StyledGlobalJobBoardJobListingCompanyLogo = styled.img`
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: contain;
`;

export const StyledGlobalJobBoardJobListingDetailsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledGlobalJobBoardJobListingInformationsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const StyledGlobalJobBoardJobListingInformationsSeparator = styled.div`
  box-sizing: border-box;
  font-family: ${font.familyMontserrat};
  font-size: 14px;
  font-weight: 400;
  line-height: '17.07px';
  color: ${colors.neutral_800};
`;

export const StyledGlobalJobBoardJobListingDateAddedWrapper = styled.div`
  box-sizing: border-box;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
