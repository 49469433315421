import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../../function-utils/log-out';
import { useAuthForms } from '../../hooks/use-auth-forms';
import { loadSubscriptionPlans } from '../../../function-utils/subscription-plan-utils';
import { SubscriptionPlan } from '../super-admin/subscription-plans/interface';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { loadStripe } from '@stripe/stripe-js';
import { Spinner } from '../../icons/spinner';
import {
  LogInWrapper,
  LogInFormContainer,
  TextContainer,
  CompanyTitle,
  CompanySubtitle,
  Description,
  LogInFormWrapper,
} from '../../login/login.styled';
import logoTeam from '../../../logo_team.png';
import {
  SubTitle,
  Title,
  Wrapper,
  SubscriptionPlansAndToggleButtonsWrapper,
  SubscriptionPlansTextWrapper,
  ToggleButtonsWrapper,
  Button,
  ButtonsWrapper,
} from './change-plan.styled';
import { Toggle } from './toggle/toggle';
import { PaymentPlans } from './payment-plans-table/payment-plans';
import { Xicon } from '../../icons/x-skill';

interface ChangePlanPageProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  refetchUser: () => Promise<void>;
}

export const ChangePlanPage: React.FC<ChangePlanPageProps> = ({
  user,
  setUser,
  refetchUser,
}) => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { signUpData, setSignUpData } = useAuthForms();
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [selectedPlanAmount, setSelectedPlanAmount] = useState<number | null>(
    null
  );

  const handleLogoClick = () => {
    if (navigate) navigate('/');
  };

  useEffect(() => {
    let isMounted = true;

    const fetchPlans = async () => {
      setLoading(true);
      try {
        const plans = await loadSubscriptionPlans();
        if (isMounted) {
          setPlans(plans);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching subscription plans:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    const fetchUser = async () => {
      setUser(null);
      await refetchUser();
    };

    fetchPlans();
    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const setSelectedPlan = (planId: string, amount: number) => {
    setSelectedPlanId(planId);
    setSelectedPlanAmount(amount);
    console.log(`Selected Plan ID: ${planId}, Amount: ${amount}`);
  };

  const handleUpgrade = async () => {
    if (selectedPlanId && selectedPlanAmount) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiRoute}/create-checkout-session`,
          {
            planId: selectedPlanId,
            customerEmail: user?.email,
          }
        );

        const { checkoutSessionId } = response.data;

        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
        );

        if (!stripe) {
          console.error('Stripe failed to initialize.');
          setLoading(false);

          return;
        }

        const stripeResult = await stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        });

        if (stripeResult.error) {
          console.error(
            'Error redirecting to Stripe checkout:',
            stripeResult.error.message
          );
        } else {
          navigate('/payment-success');
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.error(
          'Error during plan upgrade:',
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    } else {
      console.error('Please select a plan before upgrading.');
    }
  };

  const handleLogout = async () => {
    await logOut(setUser, navigate);
  };

  const [isMonthly, setIsMonthly] = useState(true);

  const handleToggle = (toggleState: boolean) => {
    setIsMonthly(toggleState);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <LogInWrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
        />
        <CompanyTitle>WorWise Client Board</CompanyTitle>
        <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
        <Description>
          Welcome back! Please enter your details to start optimizing your
          team’s hiring process.
        </Description>
      </TextContainer>
      <LogInFormWrapper id="LoginFormWrapper">
        <LogInFormContainer maxWidth="929px" overflow="none">
          <Wrapper key={user?.id}>
            <Title>
              <Xicon />
              Your Trial Has Expired
            </Title>
            <SubTitle>
              To continue using our services, please upgrade your plan.
            </SubTitle>
            <SubscriptionPlansAndToggleButtonsWrapper>
              <SubscriptionPlansTextWrapper>
                Subscription Plans
              </SubscriptionPlansTextWrapper>
              <ToggleButtonsWrapper>
                <Toggle isMonthly={isMonthly} handleToggle={handleToggle} />
              </ToggleButtonsWrapper>
            </SubscriptionPlansAndToggleButtonsWrapper>
            <PaymentPlans
              user={user}
              isSignUp={false}
              setSignUpData={setSignUpData}
              signUpData={signUpData}
              plans={plans}
              setSelectedPlan={setSelectedPlan}
              defaultSelectedPlanId={user?.selected_plan_id || null}
              isMonthly={isMonthly}
            />
            <ButtonsWrapper>
              <Button onClick={handleLogout}>Logout</Button>
              <Button onClick={handleUpgrade} disabled={loading}>
                {loading ? <Spinner /> : 'Upgrade Plan'}
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </LogInFormContainer>
      </LogInFormWrapper>
    </LogInWrapper>
  );
};
