import React from 'react';
import {
  StyledGlobalJobBoardJustLandedMainWrapper,
  StyledGlobalJobBoardJustLandedTitleAndIcon,
} from '../../../../../components/featured-jobs/global-job-board-featured-jobs.styled';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import ClockIcon from '../../../../../../../icons/clock';
import GlobalJobBoardJustLandedListing from '../../../../../components/featured-jobs/listing/global-job-board-featured-jobs-listing';
import { useJobListings } from '../../../../hooks/job-listings-context';
import { NavigateFunction } from 'react-router-dom';

interface GlobalJobBoardJustLandedProps {
  navigate: NavigateFunction;
}

const GlobalJobBoardJustLanded: React.FC<GlobalJobBoardJustLandedProps> = ({
  navigate,
}) => {
  const { jobListings } = useJobListings();

  return (
    <StyledGlobalJobBoardJustLandedMainWrapper>
      <StyledGlobalJobBoardJustLandedTitleAndIcon>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={500}
          lineHeight="19.5px"
          color={colors.base_black}
          margin="0"
        >
          Just Landed
        </Text>
        <ClockIcon />
      </StyledGlobalJobBoardJustLandedTitleAndIcon>
      {jobListings.slice(0, 3).map((job, index) => (
        <GlobalJobBoardJustLandedListing
          key={index}
          jobListing={job}
          backgroundColor={colors.ghostWhite}
          navigate={navigate}
        />
      ))}
    </StyledGlobalJobBoardJustLandedMainWrapper>
  );
};

export default GlobalJobBoardJustLanded;
