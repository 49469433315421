import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../../../constants/api-constants';

export interface GlobalJobBoardJobDataTypes {
  jobId: string;
  generalCompanyInfo: string;
  jobPosition: string;
  jobDescription: string;
  jobCategory: string;
  specialty: string;
  skills: string[];
  seniorityLevel: string;
  industry: string;
  jobDuration: string;
  responsibilities: string;
  workLocation: string;
  location: string;
  benefits: string;
  status: string;
  jobApplications: number;
  expirationDate?: string;
  analyzeCandidatesWithAi: boolean;
  companyId: string;
}

export interface GlobalJobBoardCompanyDataTypes {
  name: string;
  profilePicture?: string;
  location: string;
  email: string;
  phoneNumber: string;
  website: string;
}

export interface GlobalJobBoardJobAndCompanyDataTypes {
  job: GlobalJobBoardJobDataTypes;
  company: GlobalJobBoardCompanyDataTypes | null;
}

const useGlobalJobBoardJobAndCompanyData = (jobId: string | undefined) => {
  const [globalJobBoardJobAndCompanyData, setGlobalJobBoardJobAndCompanyData] =
    useState<GlobalJobBoardJobAndCompanyDataTypes | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    axios
      .get<GlobalJobBoardJobAndCompanyDataTypes>(
        `${apiRoute}/global-job-board/job/${jobId}`
      )
      .then((response) => {
        setGlobalJobBoardJobAndCompanyData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setError('Failed to fetch job and company data');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jobId]);

  return { globalJobBoardJobAndCompanyData, loading, error };
};

export default useGlobalJobBoardJobAndCompanyData;
