import styled from 'styled-components';
import { colors } from '../../../../../style-utils/colors';
import { font } from '../../../../../style-utils/text';

export const StyledGlobalJobBoardNavbarMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 24px 140px;
  background-color: ${colors.white};
`;

export const StyledGlobalJobBoardNavbarLogoAndMenuWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 56px;
`;

export const StyledGlobalJobBoardNavbarLogo = styled.img`
  box-sizing: border-box;
  height: 36px;
  cursor: pointer;
`;

export const StyledGlobalJobBoardNavbarMenuItemsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

export const StyledGlobalJobBoardNavbarMenuItem = styled.div<{
  selected: boolean;
}>`
  box-sizing: border-box;
  font-family: ${font.familyMontserrat};
  font-size: 16px;
  font-weight: ${({ selected }) => (selected ? 700 : 500)};
  color: ${({ selected }) => (selected ? colors.secondary_500 : colors.black)};
  cursor: pointer;
`;

export const StyledGlobalJobBoardNavbarBtnsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const StyledGlobalJobBoardNavbarBtn = styled.button<{
  isSignUpBtn: boolean;
}>`
  box-sizing: border-box;
  padding: 10px 24px;
  border-radius: 40px;
  border-style: none;
  background-color: ${({ isSignUpBtn }) =>
    isSignUpBtn ? colors.secondary_500 : 'transparent'};
  font-family: ${font.familyMontserrat};
  font-size: 16px;
  font-weight: 500;
  color: ${({ isSignUpBtn }) => (isSignUpBtn ? colors.white : colors.black)};
  cursor: pointer;
`;
