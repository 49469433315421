import React from 'react';
import {
  StyledGlobalJobBoardActiveJobsFilterAndTotalResults,
  StyledGlobalJobBoardActiveJobsMainWrapper,
  StyledGlobalJobBoardActiveJobsShowMoreBtn,
  StyledGlobalJobBoardActiveJobsSortyBy,
  StyledGlobalJobBoardActiveJobsSortyByMainWrapper,
} from './global-job-board-active-jobs.styled';
import { Text, font } from '../../../../../../../../style-utils/text';
import { colors } from '../../../../../../../../style-utils/colors';
import { DownArrow } from '../../../../../../../icons/down-arrow';
import { useJobListings } from '../../../../hooks/job-listings-context';
import GlobalJobBoardJobListing from './global-job-board-job-listing';
import { NavigateFunction } from 'react-router-dom';
import { Spinner } from '../../../../global-job-board.styled';

interface GlobalJobBoardActiveJobsProps {
  navigate: NavigateFunction;
}

const GlobalJobBoardActiveJobs: React.FC<GlobalJobBoardActiveJobsProps> = ({
  navigate,
}) => {
  const { jobListings, jobCount, isLoading, loadMoreJobs } = useJobListings();

  return (
    <StyledGlobalJobBoardActiveJobsMainWrapper>
      <StyledGlobalJobBoardActiveJobsFilterAndTotalResults>
        <Text
          fontFamily={font.familyMontserrat}
          fontSize={16}
          fontWeight={400}
          lineHeight="19.5px"
          color={colors.base_black}
          margin="0"
        >
          Search results: {jobCount} Jobs
        </Text>
        <StyledGlobalJobBoardActiveJobsSortyByMainWrapper>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={400}
            lineHeight="19.5px"
            color={colors.base_black}
            margin="0"
          >
            Sort By:
          </Text>
          <StyledGlobalJobBoardActiveJobsSortyBy>
            <Text
              fontFamily={font.familyMontserrat}
              fontSize={16}
              fontWeight={500}
              lineHeight="19.5px"
              color={colors.base_black}
              margin="0"
            >
              Date added
            </Text>
            <DownArrow />
          </StyledGlobalJobBoardActiveJobsSortyBy>
        </StyledGlobalJobBoardActiveJobsSortyByMainWrapper>
      </StyledGlobalJobBoardActiveJobsFilterAndTotalResults>
      {jobListings.map((job, index) => (
        <GlobalJobBoardJobListing
          key={index}
          jobListing={job}
          navigate={navigate}
        />
      ))}
      {isLoading && <Spinner />}
      {!isLoading && jobCount > 0 && (
        <StyledGlobalJobBoardActiveJobsShowMoreBtn onClick={loadMoreJobs}>
          <Text
            fontFamily={font.familyMontserrat}
            fontSize={16}
            fontWeight={400}
            lineHeight="19.5px"
            color={colors.secondary_400}
            margin="0"
          >
            Show More Jobs
          </Text>
        </StyledGlobalJobBoardActiveJobsShowMoreBtn>
      )}
    </StyledGlobalJobBoardActiveJobsMainWrapper>
  );
};

export default GlobalJobBoardActiveJobs;
