import React, { useEffect, useRef, useState } from 'react';
import {
  ApplicantsContentWrapper,
  ApplicantStatusModal,
  ApplicantStatusModalBackground,
  CancelConfirmButton,
  DownArrowContainer,
  JobSelectMenuDiv,
  ModalButtonsDiv,
  StyledFirstTh,
  StyledSelectMenu,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledSecondThead,
  StyledTr,
  TimePickersContainer,
  TimePickerSelectMenu,
  UpdateStatusInput,
  AiAnalysisButtonContainer,
  StyledSelectJobTitleAndImgWrapper,
  StyledArrowIconWrapper,
  ArrowIconContainer,
  StyledApplicantsViewCommentsBtn,
  DocsContainer,
  TableContainer,
  AiAnalysisRegenerateAbsoluteBtn,
} from './candidates.styled';
import { ButtonContainer } from './candidates.styled';
import { createItem, fetchAllItems } from '../../../requests/requests';
import {
  CandidateDataWithInterviewLastStatus,
  CandidatesWithMatchScores,
  JobApplicationData,
  JobOfferData,
} from '../../../../interfaces/job-interfaces';
import { font, Text } from '../../../../style-utils/text';
import { useSelectedJob } from '../../../../context/selected-job-context';
import { ApplicantStatusData } from '../../../../interfaces/applicant-status-interfaces';
// import { usePagination } from '../../../hooks/use-pagination';
// import { PaginationButtons } from '../../../table-pagination/table-pagination';
import { APPLICANT_STATUSES_ENUM } from '../../../../enums/applicant-statuses';
import { UpdatedApplicantStatusData } from '../../../../interfaces/applicant-status-interfaces';
import { SelectDate } from './date-picker';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import { hours, minutes } from '../../../../constants/date-time';
import { DownArrow } from '../../../icons/down-arrow';
import { colors } from '../../../../style-utils/colors';
import { BulletPoint } from '../../../icons/bullet-point';
import { AiAnalysis } from '../ai-analysis/ai-analysis';
// import { SelectFieldDownArrowIcon } from '../../../icons/select-field-down-arrow-icon';
// import { SelectFieldUpArrow } from '../../../icons/select-field-up-arrow';
import { ChevronDownIcon } from '../../../icons/chevron-down-icon';
import MessagePopup from '../../../messages/message-popup/message-popup';
import CandidateComments from './candidates-table-components/candidates-table-comments/candidate-comments';
import { DownloadDisabled } from '../../../icons/download-disabled';
import { DownloadEnabled } from '../../../icons/download-enabled';
import { useLocation } from 'react-router-dom';
import { Spinner } from '../../../icons/spinner';
import { useEmployeeCVData } from '../../company-cv/hooks/use-employee-company-cv-data';
import LoadingSpinner from '../../../icons/small-spinner';
import EmployeesErrorGeneratingCompanyCvPopup from '../../employees/employees-error-generating-company-cv-popup/employees-error-generating-company-cv-popup';
import RefreshIcon from '../../../icons/refresh-icon';
import { Tooltip } from 'react-tooltip';

const newApplicantStatus: UpdatedApplicantStatusData = {
  candidate_id: '',
  status: '',
  type_of_interview: '',
  interview_date: undefined,
  interview_time: '',
  interview_status: '',
  comment_after_interview: '',
};

export interface Applicant {
  additional_files: string | URL | undefined;
  comment: string | null;
  company_id: string;
  createdAt: string;
  cv: string | URL | undefined;
  email: string;
  first_name: string;
  id: string;
  job_id: string;
  last_name: string;
  message: string;
  phone_number: string;
  portfolio: string | URL | undefined;
  referral: string;
  status: string;
  updatedAt: string;
  ai_info: string;
}

const interviewTypes = ['HR', 'Technical', 'Executive', 'Send Offer'];

export const CandidatesTable = ({
  company,
  fromPage,
  navigate,
  triggerReRender,
}: // eslint-disable-next-line
any) => {
  const {
    selectedJob,
  }: {
    selectedJob: JobOfferData | null | undefined;
  } = useSelectedJob();
  const [candidatesData, setCandidatesData] = useState<JobApplicationData[]>(
    []
  );
  const [
    candidatesWithLastInterviewStatus,
    setCandidatesWithLastInterviewStatus,
  ] = useState<CandidateDataWithInterviewLastStatus[]>([]);
  const [candidatesWithMatchScores, setCandidatesWithMatchScores] = useState<
    CandidatesWithMatchScores[]
  >([]);
  const [jobOffersData, setJobOffersData] = useState<JobOfferData[]>([]);

  const location = useLocation();
  const [selectedJobOfferFromFilter, setSelectedJobOfferFromFilter] =
    useState<JobOfferData | null>(
      location.state?.selectedJobOfferFromFilter
        ? location.state?.selectedJobOfferFromFilter
        : selectedJob
        ? selectedJob
        : null
    );

  const [isApplicantStatusModalVisible, setIsApplicantStatusModalVisible] =
    useState<boolean>(false);

  const [updateApplicantStatus, setUpdateApplicantStatus] =
    useState<UpdatedApplicantStatusData>(newApplicantStatus);
  const [hour, setHour] = useState<string | undefined>(undefined);
  const [minute, setMinute] = useState<string | undefined>(undefined);
  const [amPm, setAmPm] = useState<string | undefined>(undefined);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedStatusCandidateId, setSelectedStatusCandidateId] =
    useState('');
  const [selectedCandidateJobId, setselectedCandidateJobId] = useState('');

  const [applicantsStatuses, setApplicantsStatuses] = useState<
    ApplicantStatusData[]
  >([]);
  const [commentForApplicantStatus, setCommentForApplicantStatus] =
    useState<string>('');
  const [activeCommentCandidateId, setActiveCommentCandidateId] = useState<
    string | null
  >('');
  const [selectedApplicantId, setSelectedApplicantId] = useState<string>('');
  const [selectedApplicant, setSelectedApplicant] = useState<
    Applicant | undefined
  >();
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const [viewAnalysis, setViewAnalysis] = useState(false);
  const [
    candidateCommentsVisibleStatusAndID,
    setCandidateCommentsVisibleStatusAndID,
  ] = useState<{ visible: boolean; applicantId: string }>({
    visible: false,
    applicantId: '',
  });
  const [scheduleInterviewErrors, setScheduleInterviewErrors] = useState({
    type_of_interview: '',
    interview_date: '',
    interview_time: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCVData, error } = useEmployeeCVData();
  const [
    loadingGenerateCompanyCvCandidateId,
    setLoadingGenerateCompanyCvCandidateId,
  ] = useState<string | null>(null);
  const [
    errorGenerateingCompanyCvPopUpVisible,
    setErrorGenerateingCompanyCvPopUpVisible,
  ] = useState<boolean>(false);
  const [employeeIdToCreateCompanyCV, setEmployeeIdToCreateCompanyCV] =
    useState<string>();

  useEffect(() => {
    if (error) {
      setErrorGenerateingCompanyCvPopUpVisible(true);
    }
  }, [error]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  useEffect(() => {
    navigate(location.pathname, { replace: true });
  }, [selectedJobOfferFromFilter]);

  const validateForm = () => {
    const newErrors = {
      type_of_interview: '',
      interview_date: '',
      interview_time: '',
    };

    let isValid = true;

    if (selectedStatus === '2') {
      if (!updateApplicantStatus.type_of_interview) {
        newErrors.type_of_interview = 'Please select a type of interview.';
        isValid = false;
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const interviewDate = updateApplicantStatus.interview_date;

      if (!interviewDate) {
        newErrors.interview_date = 'Please select an interview date.';
        isValid = false;
      } else {
        const selectedDate = new Date(interviewDate);
        if (isNaN(selectedDate.getTime())) {
          newErrors.interview_date = 'Invalid date format.';
          isValid = false;
        } else if (selectedDate < today) {
          newErrors.interview_date =
            'The interview date cannot be in the past.';
          isValid = false;
        }
      }

      if (!hour || !minute || !amPm) {
        newErrors.interview_time = 'Please select an interview time.';
        isValid = false;
      }
    }

    setScheduleInterviewErrors(newErrors);

    return isValid;
  };

  const toggleAnalysis = () => {
    setViewAnalysis(!viewAnalysis);
  };

  const [showSelectJobTitle, setShowSelectJobTitle] = useState<number | null>(
    null
  );
  const jobTitleDropdownRef = useRef<HTMLDivElement>(null);

  const toggleSelectJobTitle = (index: number) => {
    setShowSelectJobTitle((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      jobTitleDropdownRef.current &&
      !jobTitleDropdownRef.current.contains(event.target as Node)
    ) {
      setShowSelectJobTitle(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const commentTextareaRef = useRef<HTMLTextAreaElement>(null);

  const { name, id } = company;

  const [sortedCandidatesWithInterview, setSortedCandidatesWithInterview] =
    useState<CandidateDataWithInterviewLastStatus[]>([]);

  const sortCandidates = (
    candidates: CandidateDataWithInterviewLastStatus[],
    matchScores: CandidatesWithMatchScores[]
  ): {
    sortedCandidates: CandidateDataWithInterviewLastStatus[];
    sortedMatchScores: CandidatesWithMatchScores[];
  } => {
    const indexedCandidates = candidates.map((candidate, index) => ({
      candidate,
      index,
    }));

    const candidatesWithInterview = indexedCandidates.filter(
      (item) => item.candidate.lastInterviewStatus !== null
    );

    const candidatesWithoutInterview = indexedCandidates.filter(
      (item) => item.candidate.lastInterviewStatus === null
    );

    candidatesWithInterview.sort((a, b) => {
      const statusA = a.candidate.lastInterviewStatus ?? '';
      const statusB = b.candidate.lastInterviewStatus ?? '';

      if (statusA < statusB) {
        return -1;
      }
      if (statusA > statusB) {
        return 1;
      }

      return 0;
    });

    const sortedIndexedCandidates = [
      ...candidatesWithInterview,
      ...candidatesWithoutInterview,
    ];

    const sortedCandidates = sortedIndexedCandidates.map(
      (item) => item.candidate
    );
    const sortedIndices = sortedIndexedCandidates.map((item) => item.index);

    const sortedMatchScores = sortedIndices.map((index) => matchScores[index]);

    return { sortedCandidates, sortedMatchScores };
  };

  // eslint-disable-next-line
  const sortHotCandidatesAndRejected = (
    candidates: CandidateDataWithInterviewLastStatus[],
    matchScores: CandidatesWithMatchScores[]
  ): {
    sortedCandidates: CandidateDataWithInterviewLastStatus[];
    sortedMatchScores: CandidatesWithMatchScores[];
  } => {
    const indexedCandidates = candidates.map((candidate, index) => ({
      candidate,
      index,
    }));

    indexedCandidates.sort((a, b) => {
      if (a.candidate.status === '3' && b.candidate.status !== '3') {
        return -1;
      }
      if (a.candidate.status !== '3' && b.candidate.status === '3') {
        return 1;
      }
      if (a.candidate.status === '4' && b.candidate.status !== '4') {
        return 1;
      }
      if (a.candidate.status !== '4' && b.candidate.status === '4') {
        return -1;
      }

      return 0;
    });

    const sortedCandidates = indexedCandidates.map((item) => item.candidate);
    const sortedIndices = indexedCandidates.map((item) => item.index);

    const sortedMatchScores = sortedIndices.map((index) => matchScores[index]);

    return { sortedCandidates, sortedMatchScores };
  };

  useEffect(() => {
    if (
      candidatesWithLastInterviewStatus.length > 0 &&
      candidatesWithMatchScores.length > 0
    ) {
      const { sortedCandidates, sortedMatchScores } = sortCandidates(
        [...candidatesWithLastInterviewStatus],
        [...candidatesWithMatchScores]
      );
      setSortedCandidatesWithInterview(sortedCandidates);
      setCandidatesWithMatchScores(sortedMatchScores);
    }
  }, [candidatesWithLastInterviewStatus]);

  const getCandidatesFromDB = async () => {
    let response;

    if (id) {
      if (selectedJobOfferFromFilter) {
        response = await fetchAllItems(
          `/applicants/${id}/${selectedJobOfferFromFilter.id}`
        );
      } else {
        response = await fetchAllItems(`/applicants/${id}`);
      }
    }

    if (response) {
      // eslint-disable-next-line
      const responseWithMatchScores = response.map((candidate: any) => {
        const aiInfo = candidate.ai_info;
        console.log('candidate ai info');
        console.log(aiInfo);
        let matchScore;

        if (aiInfo) {
          // Try to parse ai_info as JSON
          let parsed;
          try {
            parsed = JSON.parse(aiInfo);
            if (parsed && parsed.percentage_match !== undefined) {
              matchScore = parsed.percentage_match;
            } else {
              // If percentage_match is not in the parsed object, use regex
              matchScore = extractPercentage(aiInfo);
            }
          } catch (e) {
            console.warn(
              'ai_info is not valid JSON, attempting regex extraction'
            );
            matchScore = extractPercentage(aiInfo);
          }
        } else {
          matchScore = -1; // Set matchScore to -1 if ai_info is null
        }

        return {
          candidate: candidate,
          matchScore: matchScore,
        };
      });

      // Sort candidates based on match scores (highest score first), -1s go to the bottom
      responseWithMatchScores.sort((a, b) => b.matchScore - a.matchScore);

      // Return only the candidates without the score
      response = responseWithMatchScores.map(({ candidate }) => candidate);
      setCandidatesWithMatchScores(responseWithMatchScores);
    }

    setCandidatesData(response as []);
  };

  // Helper function to extract percentage using regex
  function extractPercentage(text: string): number {
    const regex = /(\d{1,3})%/;
    const match = text.match(regex);
    if (match) {
      return parseInt(match[1], 10);
    } else {
      return 0; // Default to 0 if no percentage is found
    }
  }

  const getJobsFromDB = async () => {
    // eslint-disable-next-line
    let response: any = [];

    //This is a post request (but it's acctually a get req) to get only the job offers that are assigned to a specific company
    if (id) {
      response = await createItem(`${name}/hiring/job-offers`, company);

      if (response) {
        response = response.filter(
          (job: JobOfferData) =>
            job.status === 'active' || job.status === 'draft'
        );
      }
    }

    setJobOffersData(response as JobOfferData[]);
  };

  const getApplicantStatusesFromDB = async () => {
    const response = await fetchAllItems(`/applicant-status`);
    setApplicantsStatuses(response as []);
  };

  const getAllRelevantData = () => {
    getApplicantStatusesFromDB();
    getCandidatesFromDB();
    getJobsFromDB();
    gettingLastInterviewStatusesForCandidates();
  };

  useEffect(() => {
    console.log('REceaved state:', selectedJobOfferFromFilter?.job_position);
    getAllRelevantData();
  }, [selectedJobOfferFromFilter]);

  const handleFilterByJob = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedJobId = event.target.value;
    navigate(location.pathname, { replace: true });

    if (selectedJobId === 'all jobs') {
      setSelectedJobOfferFromFilter(null);
    } else {
      const selectedJob = jobOffersData.find((job) => job.id === selectedJobId);

      if (selectedJob) {
        setSelectedJobOfferFromFilter(selectedJob);
      }
    }
  };

  const handleApplicantStatusSelect = (
    status: string,
    candidateId: string,
    jobId: string
  ) => {
    setIsApplicantStatusModalVisible(true);
    setUpdateApplicantStatus({
      ...updateApplicantStatus,
      candidate_id: candidateId,
      status,
    });

    setSelectedStatus(status);
    setSelectedStatusCandidateId(candidateId);
    setselectedCandidateJobId(jobId);
  };

  const handleInterviewTypeSelect = (value: string) => {
    setUpdateApplicantStatus({
      ...updateApplicantStatus,
      type_of_interview: value,
    });
  };

  // eslint-disable-next-line
  const handleCommentInputChange = async (
    candidateInterviewStatus: ApplicantStatusData | null
  ) => {
    if (candidateInterviewStatus && commentForApplicantStatus.length > 0) {
      candidateInterviewStatus.comment_after_interview =
        commentForApplicantStatus;

      const response = await axios.put(
        `${apiRoute}/applicant-status`,
        candidateInterviewStatus
      );

      if (response.status === 200) {
        setCommentForApplicantStatus('');
        setActiveCommentCandidateId('');
      }
    }

    setCommentForApplicantStatus('');
    setActiveCommentCandidateId('');
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setUpdateApplicantStatus({
        ...updateApplicantStatus,
        interview_date: date,
      });
    }
  };

  // eslint-disable-next-line
  const onSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      if (selectedStatus === '2') {
        getTimeFromTimeInputs();
        const applicantStatusResponse = await axios.post(
          `${apiRoute}/applicant-status`,
          {
            applicant_status: updateApplicantStatus,
            job_id: selectedCandidateJobId,
            company_name: name,
          }
        );

        const applicantsResponse = await axios.put(
          `${apiRoute}/applicants/${selectedStatusCandidateId}`,
          {
            status: selectedStatus,
            job_id: selectedCandidateJobId,
            company_name: name,
          }
        );

        if (
          applicantStatusResponse.status === 200 &&
          applicantsResponse.status === 200
        ) {
          getApplicantStatusesFromDB();
          getCandidatesFromDB();
          gettingLastInterviewStatusesForCandidates();
          if (fromPage !== undefined) {
            triggerReRender();
          }
          console.log(
            'Status successfully added:',
            applicantStatusResponse.data
          );
        } else {
          console.error(
            'Unexpected response status:',
            applicantStatusResponse.status
          );
        }
      } else if (selectedStatus === '6') {
        console.log('tuka');
        const responseDeleteApplicant = await axios.delete(
          `${apiRoute}/applicants/${selectedStatusCandidateId}`
        );

        const responseDeleteInterview = await axios.delete(
          `${apiRoute}/applicant-interview/${selectedStatusCandidateId}`
        );
        console.log(responseDeleteInterview.status);
        if (
          (responseDeleteApplicant.status === 200 ||
            responseDeleteApplicant.status === 202) &&
          (responseDeleteInterview.status === 200 ||
            responseDeleteInterview.status === 204)
        ) {
          getApplicantStatusesFromDB();
          getCandidatesFromDB();
          gettingLastInterviewStatusesForCandidates();
          setIsApplicantStatusModalVisible(false);

          console.log(
            'Applicant deleted successfully',
            responseDeleteApplicant.data
          );
        } else {
          console.error(
            'Unexpected response status:',
            responseDeleteApplicant.status
          );
        }
      } else {
        const response = await axios.put(
          `${apiRoute}/applicants/${selectedStatusCandidateId}`,
          {
            status: selectedStatus,
            job_id: selectedCandidateJobId,
            company_name: name,
          }
        );

        if (response.status === 200 || response.status === 202) {
          getApplicantStatusesFromDB();
          getCandidatesFromDB();
          gettingLastInterviewStatusesForCandidates();
          setIsApplicantStatusModalVisible(false);

          console.log('Applicant status successfully changed:', response.data);
        } else {
          console.error('Unexpected response status:', response.status);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('Axios error:', axiosError.message);
        if (axiosError.response) {
          console.error('Response error:', axiosError.response.data);
          setPopUp({
            text: error.response?.data.message,
            type: 'error',
          });
        } else if (axiosError.request) {
          console.error('Request error:', axiosError.request);
        }
      } else {
        console.error('Non-Axios error:', error);
      }
    } finally {
      setUpdateApplicantStatus(newApplicantStatus);
      setHour(undefined);
      setMinute(undefined);
      setAmPm(undefined);

      setSelectedStatus('');
      setSelectedStatusCandidateId('');
      setselectedCandidateJobId('');
      setIsApplicantStatusModalVisible(false);
    }
    setIsApplicantStatusModalVisible(false);
  };

  const handleHour = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setHour(event.target.value);
  };
  const handleMinute = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMinute(event.target.value);
  };
  const handleAmPM = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAmPm(event.target.value);
  };

  const getTimeFromTimeInputs = () => {
    if (hour && minute && amPm) {
      let hourFormat24: number | string = parseInt(hour, 10);
      if (amPm === 'pm' && hourFormat24 !== 12) {
        hourFormat24 += 12;
      } else if (amPm === 'pm' && hourFormat24 === 12) {
        hourFormat24 = '12';
      } else if (amPm === 'am' && hourFormat24 === 12) {
        hourFormat24 = '00';
      } else if (amPm === 'am') {
        hourFormat24 = hour;
      }

      setUpdateApplicantStatus({
        ...updateApplicantStatus,
        interview_time: `${hourFormat24}:${minute}`,
      });
    }
  };

  const cancelStatusModal = () => {
    setIsApplicantStatusModalVisible(false);
    setUpdateApplicantStatus(newApplicantStatus);
    setHour(undefined);
    setMinute(undefined);
    setAmPm(undefined);

    setSelectedStatus('');
    setSelectedStatusCandidateId('');
    setselectedCandidateJobId('');

    setScheduleInterviewErrors({
      type_of_interview: '',
      interview_date: '',
      interview_time: '',
    });
  };

  useEffect(() => {
    getTimeFromTimeInputs();
  }, [amPm]);

  useEffect(() => {
    getApplicantStatusesFromDB();
    gettingLastInterviewStatusesForCandidates();
  }, [candidatesData]);

  useEffect(() => {
    const viewAiAnalysisCandidate = candidatesData.find(
      (candidate) => candidate.id === selectedApplicantId
    );
    setSelectedApplicant(viewAiAnalysisCandidate);
  }, [selectedApplicantId]);

  const getApplicantStatusKey = (
    value: string
  ): keyof typeof APPLICANT_STATUSES_ENUM | undefined => {
    const keys = Object.keys(
      APPLICANT_STATUSES_ENUM
    ) as (keyof typeof APPLICANT_STATUSES_ENUM)[];

    return keys.find((key) => {
      return APPLICANT_STATUSES_ENUM[key] === value;
    });
  };

  const gettingLastInterviewStatusesForCandidates = () => {
    const highestStatusMap = new Map();

    applicantsStatuses.forEach((status) => {
      const currentHighest = highestStatusMap.get(status.candidate_id);
      const currentStatusValue = status.id;

      if (!currentHighest || currentStatusValue > currentHighest.id) {
        highestStatusMap.set(status.candidate_id, status);
      }
    });

    const candidatesWithLastInterview = candidatesData.map((candidate) => {
      const highestStatus = highestStatusMap.get(candidate.id);

      return {
        ...candidate,
        lastInterviewStatus: highestStatus ? highestStatus : null,
      };
    });

    let filteredCandidates = candidatesWithLastInterview;
    let filteredMatchScores = candidatesWithMatchScores;

    if (fromPage === 'hotCandidates') {
      filteredCandidates = candidatesWithLastInterview.filter(
        (candidate) => candidate.status === '3'
      );
    } else if (fromPage === 'todayInterviews') {
      filteredCandidates = candidatesWithLastInterview.filter((candidate) => {
        const today = new Date().toISOString().split('T')[0];

        return (
          candidate.lastInterviewStatus?.interview_date?.split('T')[0] ===
            today && candidate.status === '2'
        );
      });
    } else if (fromPage === 'rejectedCandidates') {
      filteredCandidates = candidatesWithLastInterview.filter(
        (candidate) => candidate.status === '4'
      );
    } else if (fromPage === undefined) {
      filteredCandidates = candidatesWithLastInterview.filter(
        (candidate) => candidate.status !== '4' && candidate.status !== '5'
      );
    }

    // If there is not matching candidates, clear the table
    if (filteredCandidates.length === 0) {
      setSortedCandidatesWithInterview([]);
    }

    // Ensure matchScores are also filtered in the same order
    const filteredIndices = filteredCandidates.map((candidate) =>
      candidatesData.findIndex((c) => c.id === candidate.id)
    );

    filteredMatchScores = filteredIndices.map(
      (index) => candidatesWithMatchScores[index]
    );

    console.log('Filtered candidates:', filteredCandidates);
    console.log('Filtered match scores:', filteredMatchScores);

    setCandidatesWithLastInterviewStatus(filteredCandidates as []);
    setCandidatesWithMatchScores(filteredMatchScores as []);
  };

  // eslint-disable-next-line
  const onClickSetActiveCommentCandidateId = (candidateId: string, e: any) => {
    setActiveCommentCandidateId(candidateId);
    console.log(e);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        commentTextareaRef.current &&
        !commentTextareaRef.current.contains(event.target as Node)
      ) {
        setActiveCommentCandidateId(null);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [activeCommentCandidateId]);

  const handleChangeJobTitle = (applicantID: string, jobOfferId: string) => {
    setIsLoading(true);
    updateApplicantJobTitle(applicantID, jobOfferId);
  };
  const updateApplicantJobTitle = (applicantId: string, jobOfferId: string) => {
    axios
      .put(
        `${apiRoute}/applicant-update-job-title/${applicantId}/${jobOfferId}`
      )
      .then((response) => {
        if (response.status === 200) {
          console.log('Applicant job title updated successfully');

          getApplicantStatusesFromDB();
          getCandidatesFromDB();
          gettingLastInterviewStatusesForCandidates();

          setIsLoading(false);
          setIsApplicantStatusModalVisible(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Error:', error.response.data);
        } else if (error.request) {
          console.error('Error: No response received', error.request);
        } else {
          console.error('Error:', error.message);
        }

        setIsLoading(false); // Stop the loader in case of error
      });
  };

  const closeCandidateComments = () => {
    setCandidateCommentsVisibleStatusAndID({ visible: false, applicantId: '' });
    getCandidatesFromDB();
  };

  const regenerateApplicantCV = async (applicantId: string, jobId: string) => {
    try {
      setIsLoading(true);
      console.log(`Regenerating CV for applicant ID: ${applicantId}`);

      const response = await axios.put(
        `${apiRoute}/applicants/${applicantId}/regenerate-cv`,
        { jobId }
      );

      if (response.status === 200) {
        console.log('CV regenerated successfully:', response.data);
        setPopUp({
          text: 'CV regenerated successfully!',
          type: 'success',
        });
        getCandidatesFromDB();
      } else {
        console.error('Unexpected response status:', response.status);
        setPopUp({
          text: 'Failed to regenerate CV',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error during CV regeneration:', error);
      setPopUp({
        text: 'Error during CV regeneration',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createCompanyCV = (employeeCvUrl: string, employeeId: string) => {
    setLoadingGenerateCompanyCvCandidateId(employeeId);

    fetchCVData(employeeCvUrl, employeeId, true)
      .then(() => {
        getAllRelevantData();
      })
      .catch((error) => {
        console.error('Error fetching CV data:', error);
      })
      .finally(() => {
        setLoadingGenerateCompanyCvCandidateId(null);
      });
  };

  useEffect(() => {
    getAllRelevantData();
  }, [location.pathname, selectedJobOfferFromFilter]);

  useEffect(() => {
    const handleFocus = () => {
      getAllRelevantData();
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return (
    <ApplicantsContentWrapper>
      {isLoading ? (
        <div>
          <h2>Hold On! Processing with AI</h2>
          <Spinner />
        </div>
      ) : (
        <>
          {viewAnalysis ? (
            <AiAnalysis
              toggleAnalysis={toggleAnalysis as () => void | undefined}
              applicant={selectedApplicant}
            />
          ) : (
            <>
              <JobSelectMenuDiv isFilterJobSelect>
                <Text
                  mt={0}
                  mb={24}
                  fontWeight={600}
                  fontSize={38}
                  fontFamily={font.family}
                >
                  {fromPage === 'hotCandidates'
                    ? 'Hot prospects'
                    : fromPage === 'todayInterviews'
                    ? 'Interviews today'
                    : fromPage === 'rejectedCandidates'
                    ? 'Rejected candidates'
                    : 'Candidates'}
                </Text>
                <StyledSelectMenu
                  name="chosenJobToFilterBy"
                  value={selectedJobOfferFromFilter?.id || 'all jobs'}
                  onChange={(e) => handleFilterByJob(e)}
                  isFilterJobSelect
                >
                  <option value={'all jobs'}>{'All Jobs'}</option>
                  {jobOffersData.map((job) => (
                    <option
                      key={job.id}
                      value={job.id}
                      // selected={selectedJob?.job_position === job.job_position}
                    >
                      {job.job_position}
                    </option>
                  ))}
                </StyledSelectMenu>
                <DownArrowContainer isJobSelectionMenu>
                  <DownArrow color={colors.primary_800} />
                </DownArrowContainer>
              </JobSelectMenuDiv>
              <TableContainer>
                <StyledTable>
                  <StyledThead>
                    <StyledTr>
                      <StyledFirstTh
                        backgroundColor={colors.creme_100}
                        columnWidth={'100px'}
                      ></StyledFirstTh>
                      <StyledFirstTh
                        colSpan={3}
                        scope="colgroup"
                        backgroundColor={colors.primary_500}
                      >
                        General Info
                      </StyledFirstTh>
                      <StyledFirstTh
                        colSpan={2}
                        scope="colgroup"
                        backgroundColor={colors.primary_600}
                      >
                        Documents
                      </StyledFirstTh>
                      {fromPage !== 'rejectedCandidates' && (
                        <StyledFirstTh
                          colSpan={4}
                          scope="colgroup"
                          backgroundColor={colors.primary_700}
                        >
                          Interview
                        </StyledFirstTh>
                      )}

                      <StyledFirstTh
                        backgroundColor={colors.creme_100}
                      ></StyledFirstTh>
                    </StyledTr>
                  </StyledThead>

                  <StyledSecondThead>
                    <StyledTr>
                      <StyledTh
                        columnWidth={'100px'}
                        backgroundColor={colors.primary_300}
                      >
                        Date of application
                      </StyledTh>
                      <StyledTh
                        columnWidth={'100%'}
                        backgroundColor={colors.primary_500}
                      >
                        Name
                      </StyledTh>
                      <StyledTh
                        columnWidth={'180px'}
                        backgroundColor={colors.primary_500}
                      >
                        Contact
                      </StyledTh>
                      <StyledTh
                        columnWidth={'160px'}
                        backgroundColor={colors.primary_500}
                      >
                        Job Title
                      </StyledTh>
                      <StyledTh
                        columnWidth={'160px'}
                        backgroundColor={colors.primary_600}
                      >
                        Submitted Documents
                      </StyledTh>
                      <StyledTh
                        columnWidth={'160px'}
                        backgroundColor={colors.primary_600}
                      >
                        AI Report
                      </StyledTh>
                      {fromPage !== 'rejectedCandidates' && (
                        <>
                          <StyledTh
                            columnWidth={'160px'}
                            backgroundColor={colors.primary_800}
                          >
                            Type
                          </StyledTh>
                          <StyledTh
                            columnWidth={'160px'}
                            backgroundColor={colors.primary_800}
                          >
                            Date/Time
                          </StyledTh>
                          <StyledTh
                            columnWidth={'160px'}
                            backgroundColor={colors.primary_800}
                          >
                            Status
                          </StyledTh>
                        </>
                      )}
                      <StyledTh
                        columnWidth={'160px'}
                        backgroundColor={colors.primary_800}
                      >
                        Comments
                      </StyledTh>

                      <StyledTh
                        columnWidth={'240px'}
                        backgroundColor={colors.primary_800}
                      >
                        Application Status
                      </StyledTh>
                    </StyledTr>
                  </StyledSecondThead>

                  <tbody>
                    {/* {candidatesWithLastInterviewStatus.map((candidate, index) => ( */}
                    {sortedCandidatesWithInterview.map((candidate, index) => (
                      <>
                        <StyledTr key={index}>
                          <StyledTd data-label="Date of application">
                            {new Date(candidate.createdAt).toLocaleDateString(
                              'en-US'
                            )}
                          </StyledTd>
                          <StyledTd data-label="Name">
                            {candidate.first_name} {candidate.last_name}
                          </StyledTd>

                          <StyledTd
                            data-label="Email"
                            style={{ lineHeight: '26px' }}
                          >
                            {candidate.email} {candidate.phone_number}
                          </StyledTd>

                          <StyledTd
                            data-label="Job Title"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleSelectJobTitle(index);
                            }}
                          >
                            <StyledSelectJobTitleAndImgWrapper>
                              <StyledSelectMenu
                                isJobPositionSelect
                                border="none"
                                name="jobTitle"
                                onChange={(e) => {
                                  handleChangeJobTitle(
                                    candidate.id,
                                    e.target.value
                                  );
                                  setShowSelectJobTitle(null);
                                }}
                                onFocus={() => setShowSelectJobTitle(index)}
                                onBlur={() => setShowSelectJobTitle(null)}
                                value={
                                  jobOffersData.find(
                                    (job) => job.id === candidate.job_id
                                  )?.id || ''
                                }
                              >
                                <option value="" disabled>
                                  {jobOffersData.find(
                                    (job) => job.id === candidate.job_id
                                  )?.job_position || 'Select Job Title'}
                                </option>
                                {jobOffersData.map((jobOffer) => (
                                  <option key={jobOffer.id} value={jobOffer.id}>
                                    {jobOffer.job_position}
                                  </option>
                                ))}
                              </StyledSelectMenu>
                              <StyledArrowIconWrapper>
                                {showSelectJobTitle !== index ? (
                                  <ArrowIconContainer rotate={false}>
                                    <ChevronDownIcon />
                                  </ArrowIconContainer>
                                ) : (
                                  <ArrowIconContainer rotate={true}>
                                    <ChevronDownIcon />
                                  </ArrowIconContainer>
                                )}
                              </StyledArrowIconWrapper>
                            </StyledSelectJobTitleAndImgWrapper>
                          </StyledTd>

                          <StyledTd data-label="CV">
                            {candidate.cv ? (
                              <DocsContainer
                                onClick={() =>
                                  window.open(candidate.cv, '_blank')
                                }
                              >
                                CV <DownloadEnabled />
                              </DocsContainer>
                            ) : (
                              <DocsContainer disabled>
                                CV <DownloadDisabled />
                              </DocsContainer>
                            )}

                            {candidate.company_cv ? (
                              <DocsContainer
                                onClick={() =>
                                  navigate(
                                    `/${company.name}/${candidate.id}/company-cv/edit/${candidate.company_cv}`
                                  )
                                }
                              >
                                Company CV <DownloadEnabled />
                              </DocsContainer>
                            ) : (
                              <DocsContainer
                                onClick={() => {
                                  if (candidate.cv) {
                                    setEmployeeIdToCreateCompanyCV(
                                      candidate.id
                                    ),
                                      createCompanyCV(
                                        candidate.cv.toString(),
                                        candidate.id
                                      );
                                  } else {
                                    navigate(
                                      `/${company.name}/${candidate.id}/company-cv/new`
                                    );
                                  }
                                }}
                              >
                                Company CV
                                {loadingGenerateCompanyCvCandidateId !==
                                  candidate.id && <DownloadDisabled />}
                                {loadingGenerateCompanyCvCandidateId ===
                                  candidate.id && <LoadingSpinner />}
                              </DocsContainer>
                            )}

                            {candidate.portfolio ? (
                              <DocsContainer
                                onClick={() =>
                                  window.open(candidate.cv, '_blank')
                                }
                              >
                                Portfolio <DownloadEnabled />
                              </DocsContainer>
                            ) : (
                              <DocsContainer disabled>
                                Portfolio <DownloadDisabled />
                              </DocsContainer>
                            )}

                            {candidate.additional_files ? (
                              <DocsContainer
                                onClick={() =>
                                  window.open(candidate.cv, '_blank')
                                }
                              >
                                Other <DownloadEnabled />
                              </DocsContainer>
                            ) : (
                              <DocsContainer disabled>
                                Other <DownloadDisabled />
                              </DocsContainer>
                            )}
                          </StyledTd>
                          <StyledTd data-label="View AI">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {candidate.ai_info &&
                              candidate.ai_info.length > 0 ? (
                                <AiAnalysisButtonContainer>
                                  <ButtonContainer
                                    onClick={() => {
                                      setSelectedApplicantId(candidate.id);

                                      navigate &&
                                        navigate(
                                          `/${company.name}/applicants/${candidate.id}`,
                                          {
                                            state: {
                                              selectedJobOfferFromFilter,
                                              fromPage,
                                            },
                                          }
                                        );
                                    }}
                                    backgroundColor={colors.secondary_500}
                                    isAIAnalysisButton
                                    mt={1}
                                  >
                                    View
                                  </ButtonContainer>
                                  {candidatesWithMatchScores.length > 0 && (
                                    <Text
                                      mt={10}
                                      mb={0}
                                      fontWeight={400}
                                      fontSize={14}
                                      fontFamily={font.family}
                                    >
                                      Match{' '}
                                      {
                                        candidatesWithMatchScores[index]
                                          ?.matchScore
                                      }{' '}
                                      %{' '}
                                    </Text>
                                  )}
                                  <AiAnalysisRegenerateAbsoluteBtn
                                    className="my-anchor-element"
                                    onClick={() =>
                                      regenerateApplicantCV(
                                        candidate.id,
                                        candidate.job_id
                                      )
                                    }
                                  >
                                    <RefreshIcon
                                      size="18px"
                                      color={colors.grey}
                                    />
                                  </AiAnalysisRegenerateAbsoluteBtn>
                                  <Tooltip
                                    anchorSelect=".my-anchor-element"
                                    place="right"
                                  >
                                    Regenerate with AI
                                  </Tooltip>
                                </AiAnalysisButtonContainer>
                              ) : candidate.cv_text &&
                                candidate.cv_text.length > 10 ? (
                                <ButtonContainer
                                  id="regenerate button"
                                  onClick={() =>
                                    regenerateApplicantCV(
                                      candidate.id,
                                      candidate.job_id
                                    )
                                  }
                                  backgroundColor={colors.secondary_500}
                                  isAIAnalysisButton
                                  mt={1}
                                >
                                  Regenerate
                                </ButtonContainer>
                              ) : (
                                <ButtonContainer
                                  backgroundColor={colors.secondary_500}
                                  isAIAnalysisButton
                                  mt={1}
                                  disabled
                                >
                                  No CV Uploaded
                                </ButtonContainer>
                              )}
                            </a>
                          </StyledTd>

                          <>
                            {fromPage !== 'rejectedCandidates' && (
                              <>
                                <StyledTd data-label="Type of Interview">
                                  {candidate.lastInterviewStatus
                                    ?.type_of_interview &&
                                  candidate.status === '2'
                                    ? candidate.lastInterviewStatus
                                        ?.type_of_interview
                                    : '/'}
                                </StyledTd>

                                <StyledTd data-label="Interview date">
                                  {candidate.lastInterviewStatus
                                    ?.interview_date && candidate.status === '2'
                                    ? new Date(
                                        candidate.lastInterviewStatus.interview_date
                                      ).toLocaleDateString('en-US')
                                    : '/'}
                                  <br />
                                  {candidate.lastInterviewStatus
                                    ?.interview_time &&
                                    candidate.status === '2' &&
                                    candidate.lastInterviewStatus
                                      ?.interview_time}
                                </StyledTd>

                                <StyledTd
                                  data-label="Interview status"
                                  interviewIsPending={
                                    candidate.lastInterviewStatus
                                      ?.interview_status === 'pending'
                                  }
                                  interviewIsConfirmed={
                                    candidate.lastInterviewStatus
                                      ?.interview_status === 'confirmed'
                                  }
                                  interviewIsRejected={
                                    candidate.lastInterviewStatus
                                      ?.interview_status === 'rejected'
                                  }
                                >
                                  <BulletPoint
                                    color={
                                      candidate.lastInterviewStatus
                                        ?.interview_status === 'pending' &&
                                      candidate.status === '2'
                                        ? `${colors.primary_500}`
                                        : candidate.lastInterviewStatus
                                            ?.interview_status === 'pending' &&
                                          candidate.status === '2' &&
                                          `${colors.secondary_500}`
                                    }
                                  />
                                  <span style={{ marginLeft: '4px' }}>
                                    {candidate.lastInterviewStatus
                                      ?.interview_status &&
                                    candidate.status === '2'
                                      ? candidate.lastInterviewStatus?.interview_status
                                          .charAt(0)
                                          .toUpperCase() +
                                        candidate.lastInterviewStatus?.interview_status
                                          .slice(1)
                                          .toLowerCase()
                                      : '/'}
                                  </span>
                                </StyledTd>
                              </>
                            )}

                            {/* <StyledTd data-label="Comment after interview">
                          {!candidate.lastInterviewStatus ? (
                            '/'
                          ) : candidate.lastInterviewStatus
                              ?.comment_after_interview &&
                            candidate.status === '2' ? (
                            candidate.lastInterviewStatus
                              ?.comment_after_interview &&
                            candidate.status === '2' && (
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '4px',
                                  flexDirection: 'column',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <StyledCommentInput
                                  // type="text"
                                  ref={commentTextareaRef}
                                  name="commentForApplicantStatus"
                                  // value={
                                  //   candidate.lastInterviewStatus.candidate_id ===
                                  //   activeCommentCandidateId
                                  //     ? commentForApplicantStatus
                                  //     : ''
                                  // }
                                  defaultValue={
                                    candidate.lastInterviewStatus
                                      ?.comment_after_interview
                                  }
                                  onChange={(e) =>
                                    setCommentForApplicantStatus(e.target.value)
                                  }
                                  onClick={() =>
                                    setActiveCommentCandidateId(candidate.id)
                                  }
                                />

                                <CancelConfirmButton
                                  isConfirm={true}
                                  onClick={() =>
                                    handleCommentInputChange(
                                      candidate.lastInterviewStatus
                                    )
                                  }
                                  isSaveComment
                                >
                                  Save
                                </CancelConfirmButton>
                              </div>
                            )
                          ) : !candidate.lastInterviewStatus
                              ?.comment_after_interview &&
                            candidate.status === '2' ? (
                            <div
                              style={{
                                display: 'flex',
                                gap: '4px',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                              }}
                            >
                              <StyledCommentInput
                                // type="text"
                                ref={commentTextareaRef}
                                name="commentForApplicantStatus"
                                defaultValue={
                                  candidate?.lastInterviewStatus
                                    ?.candidate_id === activeCommentCandidateId
                                    ? commentForApplicantStatus
                                    : ''
                                }
                                placeholder={'Enter comment...'}
                                onChange={(e) =>
                                  setCommentForApplicantStatus(e.target.value)
                                }
                                onClick={() =>
                                  setActiveCommentCandidateId(candidate.id)
                                }
                              />

                              <CancelConfirmButton
                                isConfirm={true}
                                onClick={() =>
                                  handleCommentInputChange(
                                    candidate.lastInterviewStatus
                                  )
                                }
                                isSaveComment
                              >
                                Save
                              </CancelConfirmButton>
                            </div>
                          ) : (
                            '/'
                          )}
                        </StyledTd> */}
                            <StyledTd data-label="Comment after interview">
                              <StyledApplicantsViewCommentsBtn
                                onClick={() =>
                                  setCandidateCommentsVisibleStatusAndID({
                                    visible: true,
                                    applicantId: candidate.id,
                                  })
                                }
                                haveComments={candidate.haveComment}
                              >
                                {candidate.haveComment
                                  ? 'View Comment'
                                  : 'Add Comment'}
                              </StyledApplicantsViewCommentsBtn>
                            </StyledTd>
                          </>
                          <StyledTd data-label="Status" isApplicantStatusSelect>
                            <StyledSelectMenu
                              isApplicantStatusSelect
                              name="selectStatus"
                              // value={
                              //   selectedStatus.length > 0 &&
                              //   selectedStatusCandidateId === candidate.id
                              //     ? selectedStatus
                              //     : // : candidate.lastInterviewStatus
                              //       // ? candidate.lastInterviewStatus.status
                              //       //     .charAt(0)
                              //       //     .toUpperCase() +
                              //       //   candidate.lastInterviewStatus.status
                              //       //     .slice(1)
                              //       //     .toLowerCase()
                              //       //     .replace(/_/g, ' ')
                              //       candidate.status.charAt(0).toUpperCase() +
                              //       candidate.status
                              //         .slice(1)
                              //         .toLowerCase()
                              //         .replace(/_/g, ' ')
                              // }
                              onChange={(e) => {
                                handleApplicantStatusSelect(
                                  e.target.value,
                                  candidate.id,
                                  candidate.job_id
                                );
                                getAllRelevantData();
                              }}
                              onBlur={(e) => {
                                e.target.value = '';
                              }}
                            >
                              <option disabled selected value="">
                                {selectedStatus.length > 0 &&
                                selectedStatusCandidateId === candidate.id
                                  ? Object.keys(APPLICANT_STATUSES_ENUM)
                                      .find(
                                        (key) =>
                                          APPLICANT_STATUSES_ENUM[
                                            key as keyof typeof APPLICANT_STATUSES_ENUM
                                          ] === selectedStatus
                                      )
                                      ?.toLowerCase()
                                      .replace(/_/g, ' ')
                                      .split(' ')
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(' ')
                                  : Object.keys(APPLICANT_STATUSES_ENUM)
                                      .find(
                                        (key) =>
                                          APPLICANT_STATUSES_ENUM[
                                            key as keyof typeof APPLICANT_STATUSES_ENUM
                                          ] === candidate.status
                                      )
                                      ?.toLowerCase()
                                      .replace(/_/g, ' ')
                                      .split(' ')
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(' ')}
                              </option>

                              {Object.keys(APPLICANT_STATUSES_ENUM).map(
                                (status, index) => (
                                  <option
                                    key={index}
                                    value={
                                      status === 'SCHEDULE_FOR_INTERVIEW'
                                        ? '2' // postavi 1 za da zakazes interview kaj status Apply
                                        : APPLICANT_STATUSES_ENUM[
                                            status as keyof typeof APPLICANT_STATUSES_ENUM
                                          ]
                                    }
                                    // disabled={status === 'APPLIED'}
                                  >
                                    {status.charAt(0).toUpperCase() +
                                      status
                                        .slice(1)
                                        .toLowerCase()
                                        .replace(/_/g, ' ')}
                                  </option>
                                )
                              )}
                            </StyledSelectMenu>
                            <DownArrowContainer>
                              <DownArrow />
                            </DownArrowContainer>
                          </StyledTd>
                        </StyledTr>
                      </>
                    ))}
                  </tbody>
                </StyledTable>
              </TableContainer>
              {isApplicantStatusModalVisible && (
                <ApplicantStatusModalBackground
                  id="applicant-status-modal-background"
                  onClick={() => cancelStatusModal()}
                >
                  <ApplicantStatusModal
                    id="applicant-status-modal"
                    onClick={(e) => e.stopPropagation()}
                    small={updateApplicantStatus.status !== '2'}
                  >
                    {updateApplicantStatus.status === '2' ? (
                      <>
                        <UpdateStatusInput>
                          <Text
                            mt={0}
                            mb={20}
                            fontSize={26}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Schedule an Interview
                          </Text>

                          <StyledSelectMenu
                            name="type_of_interview"
                            value={updateApplicantStatus.type_of_interview}
                            onChange={(e) =>
                              handleInterviewTypeSelect(e.target.value)
                            }
                            isModalSelect
                          >
                            <option value="" disabled>
                              Select type of interview:
                            </option>
                            {interviewTypes.map((interview) => (
                              <option key={interview} value={interview}>
                                {interview}
                              </option>
                            ))}
                          </StyledSelectMenu>
                          {scheduleInterviewErrors.type_of_interview && (
                            <p style={{ color: 'red' }}>
                              {scheduleInterviewErrors.type_of_interview}
                            </p>
                          )}
                        </UpdateStatusInput>

                        <UpdateStatusInput>
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={16}
                            fontFamily={font.family}
                          >
                            Select an interview date:
                          </Text>
                          <SelectDate
                            value={updateApplicantStatus.interview_date}
                            onChange={handleDateChange}
                          />
                          {scheduleInterviewErrors.interview_date && (
                            <p style={{ color: 'red' }}>
                              {scheduleInterviewErrors.interview_date}
                            </p>
                          )}
                        </UpdateStatusInput>

                        <UpdateStatusInput>
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={16}
                            fontFamily={font.family}
                          >
                            Select an interview time:
                          </Text>
                          <TimePickersContainer>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <TimePickerSelectMenu
                                  name="hour"
                                  value={hour}
                                  onChange={handleHour}
                                >
                                  <option value=""></option>

                                  {hours.map((hour) => (
                                    <option
                                      className="select-option"
                                      key={hour}
                                      value={`${hour}`}
                                    >
                                      {hour}
                                    </option>
                                  ))}
                                </TimePickerSelectMenu>
                                <TimePickerSelectMenu
                                  name="minute"
                                  value={minute}
                                  onChange={handleMinute}
                                >
                                  <option value=""></option>

                                  {minutes.map((minute) => (
                                    <option
                                      className="select-option"
                                      key={minute}
                                      value={`${minute}`}
                                    >
                                      {minute}
                                    </option>
                                  ))}
                                </TimePickerSelectMenu>
                                <TimePickerSelectMenu
                                  name="amPm"
                                  value={amPm}
                                  onChange={handleAmPM}
                                >
                                  <option value=""></option>
                                  <option value="am">am</option>
                                  <option value="pm">pm</option>
                                </TimePickerSelectMenu>
                              </div>
                            </div>
                          </TimePickersContainer>
                          {scheduleInterviewErrors.interview_time && (
                            <p style={{ color: 'red' }}>
                              {scheduleInterviewErrors.interview_time}
                            </p>
                          )}
                        </UpdateStatusInput>
                      </>
                    ) : updateApplicantStatus.status === '6' ? (
                      <Text
                        fontSize={18}
                        fontWeight={700}
                        mb={40}
                        align={'center'}
                        fontFamily={font.family}
                      >
                        {`Are you sure you want to delete this candidate?`}
                      </Text>
                    ) : (
                      <Text
                        fontSize={18}
                        fontWeight={700}
                        mb={40}
                        align={'center'}
                        fontFamily={font.family}
                      >
                        {`Are you sure you want to change the status to "${
                          String(
                            getApplicantStatusKey(updateApplicantStatus.status)
                          )
                            .charAt(0)
                            .toUpperCase() +
                          String(
                            getApplicantStatusKey(updateApplicantStatus.status)
                          )
                            .slice(1)
                            .toLowerCase()
                            .replace(/_/g, ' ')
                        }"?`}
                      </Text>
                    )}

                    <ModalButtonsDiv
                      small={updateApplicantStatus.status === '2'}
                    >
                      <CancelConfirmButton isConfirm={true} onClick={onSubmit}>
                        Confirm
                      </CancelConfirmButton>
                      <CancelConfirmButton onClick={cancelStatusModal}>
                        Cancel
                      </CancelConfirmButton>
                    </ModalButtonsDiv>
                  </ApplicantStatusModal>
                </ApplicantStatusModalBackground>
              )}
              {/* <div style={{ width: '60%' }}>
      <PaginationButtons
        currentPage={currentPage}
        hasPreviousPage={hasPreviousPage}
        hasNextPage={hasNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        totalPages={totalPages}
      />
    </div> */}
            </>
          )}
          {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
          {candidateCommentsVisibleStatusAndID.visible && (
            <CandidateComments
              closeCandidateComments={closeCandidateComments}
              company={company}
              candidateId={candidateCommentsVisibleStatusAndID.applicantId}
            />
          )}
          {errorGenerateingCompanyCvPopUpVisible && (
            <EmployeesErrorGeneratingCompanyCvPopup
              employeeId={employeeIdToCreateCompanyCV}
              companyName={company.name}
              navigate={navigate}
              onClose={() => {
                setErrorGenerateingCompanyCvPopUpVisible(false);
              }}
            />
          )}
        </>
      )}
    </ApplicantsContentWrapper>
  );
};
