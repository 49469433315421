import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';

export const StyledGlobalJobBoardFilterMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 140px;
  background-color: ${colors.aliceBlue};
`;

export const StyledGlobalJobBoardFilterInnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledGlobalJobBoardFilterItemWrapper = styled.div<{
  isLast: boolean;
}>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;

  ${({ isLast }) =>
    !isLast &&
    `
      border-right: 0.5px solid #ccc;
      height: 70%;
    `}
`;
