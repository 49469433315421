import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const Wrapper = styled.div`
  background: ${colors.white};
  border-radius: 16px;
  padding: 40px;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 0;
  padding-bottom: 16px;
  font-size: 24px;
  font-family: ${font.family};
  color: ${colors.primary_500};
`;

export const SubTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: ${font.family}
  color: ${colors.base_black};
  font-weight: 500;
`;

export const SubscriptionPlansAndToggleButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
`;

export const SubscriptionPlansTextWrapper = styled.div`
  font-family: ${font.family};
  font-weight: 700;
  font-size: 16px;
`;

export const Button = styled.div<{ disabled?: boolean }>`
  background: ${colors.secondary_500};
  color: ${colors.white};
  font-family: ${font.family};
  font-weight: 700;
  font-size: 16px;
  width: 115px;
  height: 35px;
  cursor: ponter;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
  flex-direction: row-reverse;
`;

export const ToggleButtonsWrapper = styled.div``;
