import React, { useEffect, useState } from 'react';
import { SignUpData } from '../../../hooks/use-auth-forms';
import { InputContainer } from '../../../login/login.styled';
import { SubscriptionPlan } from '../../super-admin/subscription-plans/interface';
import { loadSubscriptionPlans } from '../../../../function-utils/subscription-plan-utils';
import { Spinner } from '../../../icons/spinner';
import { getCurrencySymbol } from '../../../../function-utils/currency-converter';
import {
  WarningMessage,
  Container,
  PlansWrapper,
  Header,
  HeaderItem,
  FirstHeaderItem,
  FeatureRow,
  FeatureName,
  FeatureItem,
  PriceRow,
  PriceItem,
  PriceLabel,
  SelectRow,
  SelectLabel,
  SelectItem,
  SelectButton,
} from './payment-plans.styled';
import { CheckOnIcon } from '../../../icons/check-on';
import { CheckOffIcon } from '../../../icons/check-off';

interface PaymentPlansProps {
  setSignUpData: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData: SignUpData;
  setSelectedPlan?: (planId: string, amount: number) => void;
  plans?: SubscriptionPlan[];
  isSignUp: boolean;
  defaultSelectedPlanId: string | null;
  // eslint-disable-next-line
  user: any;
  isMonthly: boolean;
}

export const PaymentPlans: React.FC<PaymentPlansProps> = ({
  setSignUpData,
  signUpData,
  setSelectedPlan,
  plans: initialPlans,
  isSignUp,
  defaultSelectedPlanId,
  user,
  isMonthly,
}) => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>(
    initialPlans?.filter((plan) => plan.status === 'active') || []
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!initialPlans) {
      const fetchPlans = async () => {
        setLoading(true);
        try {
          const fetchedPlans = await loadSubscriptionPlans();

          const activePlans = fetchedPlans.filter(
            (plan) => plan.status === 'active'
          );

          const sortedActivePlans = activePlans.sort((a, b) => {
            const priceA = parseFloat(a.price.amount);
            const priceB = parseFloat(b.price.amount);

            return priceA - priceB;
          });
          setPlans(sortedActivePlans);
        } catch (error) {
          console.error('Error fetching subscription plans:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchPlans();
    } else {
      const sortedInitialPlans = initialPlans
        .filter((plan) => plan.status === 'active')
        .sort((a, b) => {
          const priceA = parseFloat(a.price.amount);
          const priceB = parseFloat(b.price.amount);

          return priceA - priceB;
        });
      setPlans(sortedInitialPlans);
    }
  }, [initialPlans]);

  useEffect(() => {
    if (defaultSelectedPlanId) {
      setSignUpData({
        ...signUpData,
        payment_plan_id: defaultSelectedPlanId,
      });
    }
  }, [defaultSelectedPlanId, setSignUpData, signUpData]);

  const handlePlanSelect = (plan: SubscriptionPlan) => {
    const selectedPrice = isMonthly
      ? parseFloat(plan.price.amount) * 100
      : plan.annual_price
      ? parseFloat(plan.annual_price.amount) * 100
      : parseFloat(plan.price.amount) * 100;

    setSignUpData({
      ...signUpData,
      payment_plan_id: plan.id.toString(),
    });

    setSelectedPlan && setSelectedPlan(plan.id.toString(), selectedPrice);
  };

  console.log('Plans:', plans);
  console.log('Sign Up Data Payment Plan:', signUpData.payment_plan_id);
  console.log('Default Selected Plan ID:', defaultSelectedPlanId);

  const featureAvailability: { [feature: string]: boolean } = {};

  const allFeatures = Array.from(
    new Set(plans.flatMap((plan) => plan.features))
  );

  allFeatures.forEach((feature) => {
    featureAvailability[feature] = plans.every((plan) =>
      plan.features.includes(feature)
    );
  });

  const sortedFeatures = allFeatures.sort((a, b) => {
    const aAvailableInAll = featureAvailability[a] ? 1 : 0;
    const bAvailableInAll = featureAvailability[b] ? 1 : 0;

    if (aAvailableInAll === bAvailableInAll) {
      return a.localeCompare(b);
    }

    return bAvailableInAll - aAvailableInAll;
  });

  if (loading) {
    return <Spinner />;
  }

  if (plans.length === 0) {
    return (
      <WarningMessage>
        No active subscription plans are available at the moment.
      </WarningMessage>
    );
  }

  return (
    <>
      <InputContainer>
        {!isSignUp && !user?.subscription_plan_id && (
          <WarningMessage>
            This user is not subscribed to any plan.
          </WarningMessage>
        )}
      </InputContainer>
      <Container>
        <PlansWrapper>
          {/* Header */}
          <Header>
            <FirstHeaderItem>Plans Features</FirstHeaderItem>
            {plans.map((plan) => (
              <HeaderItem key={plan.id}>
                <div>{plan.title}</div>
              </HeaderItem>
            ))}
          </Header>

          {/* Features */}
          {sortedFeatures.map((feature) => (
            <FeatureRow key={feature}>
              <FeatureName>{feature}</FeatureName>
              {plans.map((plan) => (
                <FeatureItem
                  key={`${plan.id}-${feature}`}
                  isAvailable={plan.features.includes(feature)}
                >
                  {plan.features.includes(feature) ? (
                    <CheckOnIcon />
                  ) : (
                    <CheckOffIcon />
                  )}
                </FeatureItem>
              ))}
            </FeatureRow>
          ))}

          <PriceRow>
            <PriceLabel></PriceLabel>
            {plans.map((plan) => {
              const displayPrice = isMonthly
                ? plan.price
                : plan.annual_price || plan.price;

              return (
                <PriceItem key={`${plan.id}-price`}>
                  {`${getCurrencySymbol(displayPrice.currency)} ${
                    displayPrice.amount
                  } ${isMonthly ? '/Month' : '/Year'}`}
                </PriceItem>
              );
            })}
          </PriceRow>

          <SelectRow>
            <SelectLabel></SelectLabel>
            {plans.map((plan) => (
              <SelectItem key={`${plan.id}-select`}>
                <SelectButton
                  onClick={() => handlePlanSelect(plan)}
                  selected={signUpData.payment_plan_id === plan.id.toString()}
                >
                  {signUpData.payment_plan_id === plan.id.toString()
                    ? 'Selected Plan'
                    : 'Select Plan'}
                </SelectButton>
              </SelectItem>
            ))}
          </SelectRow>
        </PlansWrapper>
      </Container>
    </>
  );
};
