import React from 'react';
import { StyledGlobalJobBoardMainWrapper } from './global-job-board.styled';
import GlobalJobBoardNavbar from '../components/navbar/global-job-board-navbar';
import { NavigateFunction } from 'react-router-dom';
import GlobalJobBoardHero from './components/hero/global-job-board-hero';
import GlobalJobBoardFeelingLucky from './components/feeling-lucky/global-job-board-feeling-lucky';
import GlobalJobBoardFilter from './components/filter/global-job-board-filter';
import GlobalJobBoardJobs from './components/jobs/global-job-board-jobs';
import { JobListingsProvider } from './hooks/job-listings-context';
// import GlobalJobBoardFooter from '../components/footer/global-job-board-footer';

interface GlobalJobBoardTypes {
  // eslint-disable-next-line
  user: any; 
  navigate: NavigateFunction;
}

const GlobalJobBoard: React.FC<GlobalJobBoardTypes> = ({ navigate, user }) => {
  return (
    <StyledGlobalJobBoardMainWrapper>
      <GlobalJobBoardNavbar navigate={navigate} user={user} />
      <GlobalJobBoardHero />
      <GlobalJobBoardFeelingLucky />
      <JobListingsProvider>
        <GlobalJobBoardFilter />
        <GlobalJobBoardJobs navigate={navigate} />
      </JobListingsProvider>
      {/* <GlobalJobBoardFooter /> */}
    </StyledGlobalJobBoardMainWrapper>
  );
};

export default GlobalJobBoard;
